import React from "react";
import WhyCard from "./WhyCard";

import eco from "../assets/whyus/eco-friendly-svgrepo-com.svg";
import rupee from "../assets/whyus/rupee-1-frame-svgrepo-com.svg";
import trust from "../assets/whyus/trust-wallet-svgrepo-com.svg";
import pickup from "../assets/whyus/pickup-truck-svgrepo-com.svg";

const Whyus = () => {
  return (
    <div className="bg-[#f4f7f4] mt-10  pb-10">
      <h1 className="text-4xl font-bold text-center m-10  pt-9 max-sm:text-2xl">
        Why Us ?
      </h1>
      <div className="flex  justify-evenly items-center max-sm:flex-col max-sm:gap-10">
        <WhyCard
          image={eco}
          title={"Eco-Friendly Solutions"}
          description={
            "We are committed to protecting the environment by recycling your scrap helping to create a cleaner and greener planet."
          }
        />
        <WhyCard
          image={rupee}
          title={"Fair and Competitive Pricing"}
          description={
            "We offer transparent, market-driven prices for your scrap materials, ensuring you get the best value for your recyclables."
          }
        />
        <WhyCard
          image={trust}
          title={"Trusted Expertise"}
          description={
            "With extensive experience in the scrap industry, we offer expert services to ensure your scraps are managed  efficiently."
          }
        />
        <WhyCard
          image={pickup}
          title={"Fast and Reliable Pickup"}
          description={
            "We offer timely scrap collection services, ensuring your materials are picked up and processed efficiently without delays."
          }
        />
      </div>
    </div>
  );
};

export default Whyus;
