import React from "react";

const StepsCard = ({ image, title, description }) => {
  return (
    <div className="mt-10">
      <div className="bg-white rounded-md w-60 flex justify-center flex-col items-center shadow-md p-2">
        <img className="w-40" src={image} alt="" />
        <h1 className="text-center my-2 font-semibold text-lg">{title}</h1>
        <p className="text-sm text-center">{description}</p>
      </div>
    </div>
  );
};

export default StepsCard;
