import React from "react";

const TestimonialCard = ({ name, image, review }) => {
  return (
    <div className=" bg-[#ffffff] border border-green-500  w-64 rounded-md shadow-lg p-2">
      <div className="flex flex-col justify-center items-center">
        <img
          className="rounded-full h-32 w-32 my-2 object-cover border-2 border-emerald-200 "
          src={image}
          alt=""
        />
        <h1 className="text-xl font-semibold text-center ">{name}</h1>
      </div>
      <p className=" tracking-tighter p-2">{review}</p>
    </div>
  );
};

export default TestimonialCard;
