import React, { useEffect, useState } from "react";
import ScrapCard from "./ScrapCard";
import Loader from "./Loader";

const ScrapRate = () => {
  const [scrap, setScrap] = useState();
  const [loading, setLoading] = useState(true);

  const fetchedData = async () => {
    const data = await fetch(
      "https://api.kachraseth.in/v1/api/prices/city/66cecf863c2538dc15432196/web"
    );
    const response = await data.json();
    console.log(response.data);
    setScrap(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchedData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 className="text-4xl text-center font-semibold mt-5 mb-10 max-sm:text-2xl max-sm:mb-5">
            Today's scrap price
          </h1>
          <div className="flex flex-wrap flex-row justify-center gap-14 items-center max-sm:gap-7">
            {scrap.map((item, i) => (
              <ScrapCard
                key={i}
                name={item?.name}
                price={item?.price}
                icon={item?.icons}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ScrapRate;
