import React from "react";

const WhyCard = ({ image, description, title }) => {
  return (
    <div className=" w-64 flex flex-col shadow-lg justify-center items-center h-68 bg-white rounded-md px-2 py-2">
      <div className="bg-white ">
        <img className="w-24" src={image} alt="" />
      </div>
      <p className="font-bold">{title}</p>
      <p className="p-1">{description}</p>
    </div>
  );
};

export default WhyCard;
