import React from "react";
import googlePlay from "../assets/GooglePlay.jpg";
import appleStore from "../assets/AppleStore.jpg";
import logo from "../assets/logo.png";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex border shadow-lg mt-20 max-sm:flex-col">
      <div className="bg-[#ffffff] w-full py-5 max-sm:py-3">
        <div className="px-40 max-sm:px-0 max-sm:flex max-sm:justify-center max-sm:items-center max-sm:flex-col">
          <p className=" text-lg  my-2 text-[#13953F] font-bold max-sm:my-0">
            Contact Us
          </p>
          <div className=" flex items-center gap-2 font-semibold">
            <FaPhoneAlt />
            <p>7905622831</p>
          </div>

          <div className=" flex items-center gap-2 font-semibold">
            <MdEmail />
            <p>kachraseth.in@gmail.com</p>
          </div>
          <div className=" font-semibold my-5 text-lg max-sm:my-1">
            <p className="my-2 text-[#13953F] font-bold">Connect Us</p>
            <div className="flex gap-2 text-2xl">
              <Link to={""}>
                <RiInstagramFill />
              </Link>
              <Link to={""}>
                <FaFacebookSquare />
              </Link>
              <Link to={""}>
                <FaSquareTwitter />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#ffffff] w-full py-5 px-20 max-sm:px-0 max-sm:py-0">
        <p className="text-lg text-[#13953F] font-bold max-sm:text-center">
          Download the Kachraseth App Now !
        </p>
        <div className="max-sm:flex justify-center gap-5">
          <img
            className="w-44 mt-2 rounded-md max-sm:w-36 object-contain"
            src={googlePlay}
            alt=""
          />
          {/* <img
            className="w-44 mt-2 rounded-md max-sm:w-36 object-contain"
            src={appleStore}
            alt=""
          /> */}
        </div>
      </div>
      <div className=" px-10  w-full max-sm:flex flex-col justify-center items-center">
        <img className="w-52 pt-5 max-sm:w-36" src={logo} alt="" />
        <div className="flex gap-2 items-center max-sm:pb-5">
          <FaTruck className="text-2xl" />
          <p className="font-semibold">Join as vendor</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
