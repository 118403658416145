import React from "react";
import Hero from "./Hero";
import Whyus from "./Whyus";
import Steps from "./Steps";
import Testimonials from "./Testimonials";
import Services from "./Services";

const Body = () => {
  return (
    <>
      <Hero />
      <Steps />
      <Services />
      <Whyus />
      <Testimonials />
    </>
  );
};

export default Body;
