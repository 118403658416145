import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import Modal from "./Modal";

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const chnageState = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="flex items-center gap-5 sticky top-0 bg-white shadow-md">
        <Link to={"/"}>
          <div className="p-2 ml-4 ">
            <img className="w-48 max-sm:w-32 max" src={logo} />
          </div>
        </Link>
        <button onClick={chnageState}>
          <h1 className="font-bold ">Sell Scrap</h1>
        </button>
        <Link to={"/rates"}>
          <h1 className="font-bold">Scrap Rates</h1>
        </Link>
      </div>
      <Modal showModal={showModal} closeModal={chnageState} />
    </>
  );
};

export default Header;
