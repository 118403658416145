import React from "react";
import TestimonialCard from "./TestimonialCard";
import coustmer1 from "../assets/review/user.png";
import coustmer2 from "../assets/review/user.png";
import coustmer3 from "../assets/review/me.jpg";
import coustmer4 from "../assets/review/cust4.jpg";

const Testimonials = () => {
  return (
    <div className="p-1">
      <h1 className="text-4xl text-center font-bold my-10 max-sm:text-2xl">
        Why Customer Love Us
      </h1>
      <div className="flex justify-center gap-20 items-center max-sm:flex-col max-sm:gap-10">
        <TestimonialCard
          name={"Udita Saini"}
          image={coustmer1}
          review={
            "KachraSeth provides the best prices for selling waste online, with convenient pickups and a focus on eco-friendly disposal. It's a great option for value. Pickup was smooth and right on time. Highly recommended!"
          }
        />
        <TestimonialCard
          name={"Suman Singh"}
          image={coustmer2}
          review={
            "I loved how simple KachraSeth’s platform is. I got the highest price for my trash compared to other services, and the pickup was quick and efficient. Their focus on recycling adds to the appeal. Will definitely use again!"
          }
        />
        <TestimonialCard
          name={"Mithelesh Sharma"}
          image={coustmer3}
          review={
            "KachraSeth exceeded my expectations with their pricing. It’s the best option out there if you want to get paid for your waste while ensuring it’s handled responsibly. The process was easy, and pickup was quick."
          }
        />
        <TestimonialCard
          name={"Shahzad Khan"}
          image={coustmer4}
          review={
            "I was impressed by how simple and efficient KachraSeth is. Uploading my items took minutes, and they offered me the best rate I could find online. It’s a great way to declutter while helping the environment."
          }
        />
      </div>
    </div>
  );
};

export default Testimonials;
