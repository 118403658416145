import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";

const ScrapCard = ({ name, price, icon }) => {
  const [showModal, setShowModal] = useState(false);
  const chnageState = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="">
      <Modal showModal={showModal} closeModal={chnageState} />
      <div className="flex z-40">
        <Link className="" onClick={chnageState}>
          <div className="w-36  border border-[#13953F] rounded-md  shadow-lg flex flex-col items-center">
            <img className="w-14 h-14 mt-4" src={icon} alt="icon" />
            <h1 className="text-lg my-2 font-semibold max-sm:my-1">{name}</h1>
            <p className="font-semibold mb-2 pb-2 max-sm:mb-1">
              ₹
              <span className="text-xl  text-[#13953F] font-bold">{price}</span>
              /kg
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ScrapCard;
