import React from "react";

const ServiceCard = ({ image, title }) => {
  return (
    <div className="">
      <div className="bg-white rounded-md shadow-md p-2">
        <img className="w-56 h-48" src={image} alt="" />
        <h1 className="text-center mt-2 font-semibold text-xl">{title}</h1>
      </div>
    </div>
  );
};

export default ServiceCard;
