import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import error from "../assets/404 Error Page not Found with people connecting a plug-bro.svg";

const ErrorPage = () => {
  return (
    <>
      <Header />
      <div className="flex justify-center items-center pt-5">
        <img className="w-1/3" src={error} alt="image" />
      </div>

      <Footer />
    </>
  );
};

export default ErrorPage;
