import React from "react";
import process1 from "../assets/process/process1.svg";
import process2 from "../assets/process/process2.svg";
import process3 from "../assets/process/process3.svg";
import process4 from "../assets/process/process4.svg";
import StepsCard from "./StepsCard";

const Steps = () => {
  return (
    <div className="bg-[#f4f7f4] pb-10 p-2 pt-10 max-sm:pt-5 max-sm:-mt-[50%]">
      <h1 className="text-4xl  font-bold text-center mx-2 max-sm:text-2xl">
        Sell scrap in 3 easy steps
      </h1>
      <div className="flex justify-evenly items-center flex-wrap">
        {/* <StepsCard
          image={process1}
          title={"Register"}
          description={"Register whith mobile number without any hassel"}
        /> */}
        <StepsCard
          image={process2}
          title={"Select Trash"}
          description={"Select trash item that you want to sell out"}
        />
        <StepsCard
          image={process3}
          title={"Select Date & time"}
          description={"Select Date and Time according to your convenience."}
        />
        <StepsCard
          image={process4}
          title={"Pickup Boy"}
          description={
            "Our pickup genie will be at your door according to your time"
          }
        />
      </div>
    </div>
  );
};

export default Steps;
