import React from "react";
import logo from "../assets/logo.png";
import flg from "../assets/flg.svg";
import ksbannerr from "../assets/playbanner.jpg";

const Hero = () => {
  return (
    <>
      <div className="max-sm:bg-hero bg-no-repeat bg-cover">
        <div className="bg-opacity-80 bg-gray-50 bg-no-repeat">
          <div className="flex items-center justify-center py-20 max-sm:py-0 max-sm:flex-col max-sm:pt-[50%]">
            <div className=" w-[50%] px-20 font-semibold max-sm:px-0 max-sm:w-[100%] max-sm:flex max-sm:flex-col max-sm:justify-center max-sm:items-center">
              <h1 className="text-5xl max-sm:text-3xl max-sm:text-center max-sm:my ">
                TURN TRASH INTO
                <span className="text-[#008000] font-bold max-sm:text-3xl">
                  {" "}
                  CASH{" "}
                </span>
              </h1>
              <div className="flex gap-4">
                <h1 className="text-5xl max-sm:text-4xl">WITH</h1>
                <img className="w-64 max-sm:w-52" src={logo} alt="" />
              </div>
              <div className="flex items-center">
                <h1 className="text-lg">Made with love in</h1>
                <img src={flg} alt="" />
              </div>
            </div>
            <div className="w-[50%] h-96">
              <div className="flex justify-start w-full bg--500 ">
                <img
                  className="w-[80%] object-cover bg-gray-700 mt-5 max-sm:hidden"
                  src={ksbannerr}
                />
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.kachraseth.app&hl=en_IN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="text-xl mt-10 bg-[#008000] px-4 py-2 rounded-md font-semibold text-white">
                  Download App Now!
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
