import React from "react";
import ServiceCard from "./ServiceCard";
import house from "../assets/services/beach house-cuate.svg";
import factory from "../assets/services/Factory-bro.svg";
import Shopping from "../assets/services/Grocery shopping-bro.svg";

const Services = () => {
  return (
    <div className="mt-20 py-5">
      <h1 className="text-4xl font-bold pb-10 text-center max-sm:text-2xl">
        We provide pickup service for
      </h1>
      <div className="flex flex-wrap justify-evenly gap-10 ">
        <ServiceCard image={house} title={"House"} />
        <ServiceCard image={factory} title={"Factory"} />
        <ServiceCard image={Shopping} title={"Shopping Mall"} />
      </div>
    </div>
  );
};

export default Services;
