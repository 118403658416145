import React from "react";
import "../stylesheet/loading.css";
// import icon from "../assets/icon.png";

const Loader = () => {
  return (
    <div class="container">
      <div class="loading">
        <div class="loading__letter text-5xl text-[#13953F] max-sm:text-2xl">
          क
        </div>
        <div class="loading__letter text-5xl text-[#13953F] max-sm:text-2xl">
          च
        </div>
        <div class="loading__letter text-5xl text-[#13953F] max-sm:text-2xl">
          रा
        </div>
        <div class="loading__letter text-5xl text-black max-sm:text-2xl">S</div>
        <div class="loading__letter text-5xl text-black max-sm:text-2xl">E</div>
        <div class="loading__letter text-5xl text-black max-sm:text-2xl">T</div>
        <div class="loading__letter text-5xl text-black max-sm:text-2xl">H</div>
        <div class="loading__letter text-black max-sm:text-2xl">.</div>
        <div class="loading__letter text-black max-sm:text-2xl">.</div>
        <div class="loading__letter text-black max-sm:text-2xl">.</div>
      </div>
    </div>
  );
};

export default Loader;
